<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>内容管理</el-breadcrumb-item>
    <el-breadcrumb-item>文章列表</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getArticleList">
          <el-button slot="append" icon="el-icon-search" @click="getArticleList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="goAddArticle()">添加文章</el-button>
      </el-col>
    </el-row>

    <el-table :data="articleList" v-loading="loading">
      <!-- <el-table-column label="#" type="index"></el-table-column> -->
      <el-table-column label="ID" prop="id" width="70"></el-table-column>
      <el-table-column label="封面" width="100">
        <template slot-scope="scope">
          <el-image style="width: 64px; height: 64px;border-radius:6px;" :src="base_image_url + '/'+scope.row.article_image"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="文章标题" prop="article_title">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.article_title_uy}}</div>
          <div>{{scope.row.article_title}}</div>
        </template>
      </el-table-column>
      <el-table-column label="文章分类" width="200">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.article_category_name_uy}}</div>
          <div>{{scope.row.article_category_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="article_create_time" width="200"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteArticle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      loading: true,
      total: 0,
      articleList: [],
      base_image_url: window.base_image_url
    }
  },
  created() {
    this.getArticleList()
  },
  methods: {
    async getArticleList() {
      const { data: res } = await this.$http.get('article', { params: this.queryInfo })
      if (res.code !== 200) {
        this.$message.error('数据读取失败')
      }
      this.total = res.data.total
      this.articleList = res.data.data
      this.loading = false
      this.$message.success('数据获取成功')
    },
    goAddArticle() {
      this.$router.push('article/add')
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getArticleList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getArticleList()
    },
    showEditDialog(id) {
      this.$router.push({ path: '/article/edit', query: { id: id } })
    },
    async deleteArticle(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('article/' + id)
      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getArticleList()
    }
  }
}
</script>

<style scoped>

</style>
