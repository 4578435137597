<template>
<div>
  <BreadCrumb msg1="名称管理" msg2="添加名称" />
  <el-card>
    <el-row>
      <el-col :span="12">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="维文" prop="name_uy">
            <el-input v-model="addForm.name_uy"></el-input>
          </el-form-item>

          <el-form-item label="名称分类" prop="isim_cate_id">
            <el-select v-model="addForm.isim_cate_id" placeholder="请选择分类" style="width:80%;">
              <el-option v-for="item in isim_cate_list" :key="item.id" :label="item.isim_cate_name + ' - ' + item.isim_cate_name_uy" :value="item.id" style="cate_list">
                <span>{{ item.isim_cate_name }}</span>
                <span style="float:right;" class="uy-text">{{ item.isim_cate_name_uy }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="类别" prop="isim_leibie">
            <el-radio-group v-model="addForm.isim_leibie" @change="lb_change">
              <el-radio :label="1">通用</el-radio>
              <el-radio :label="2">人名</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="addForm.isim_sex" @change="lb_change" :disabled="is_human">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="双胞胎">
                <el-checkbox v-model="addForm.isim_twins" :disabled="is_human">双胞胎</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="名称大意" prop="isim_manisi">
            <el-input v-model="addForm.isim_manisi" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="维文大意" prop="isim_manisi_uy">
            <el-input v-model="addForm.isim_manisi_uy" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="英文" prop="isim_english_name">
            <el-input v-model="addForm.isim_english_name"></el-input>
          </el-form-item>
          <el-form-item label="拉丁文" prop="isim_lading_name">
            <el-input v-model="addForm.isim_lading_name"></el-input>
          </el-form-item>
          <el-form-item label="标签" prop="isim_tag">
            <el-tag :key="index" v-for="(tag, index) in isim_tag" closable :disable-transitions="false" @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
          <el-form-item label="标签(维文)" prop="isim_tag_uy">
            <el-tag :key="index" v-for="(tag, index) in isim_tag_uy" closable :disable-transitions="false" @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible_uy" v-model="inputValue_uy" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm_uy" @blur="handleInputConfirm_uy">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput_uy">+ New Tag</el-button>
          </el-form-item>
          <el-form-item label="备注" prop="isim_beihu">
            <el-input v-model="addForm.isim_beihu" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="isim_beihu_uy">
            <el-input v-model="addForm.isim_beihu_uy" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row align="center">
      <el-col :offset="6">
        <el-button type="primary" @click="savaAddForm">保存</el-button>
        <el-button>重置</el-button>
      </el-col>
    </el-row>
  </el-card>
</div>
</template>

<script>
import BreadCrumb from '../../components/Breadcrumb'
export default {
  name: 'AddIsim',
  components: {
    BreadCrumb
  },
  data() {
    return {
      addForm: {
        name: '',
        name_uy: '',
        isim_cate_id: '',
        isim_leibie: 1,
        isim_sex: 1,
        isim_twins: false, //  twins -> 双胞胎
        isim_manisi: '',
        isim_manisi_uy: '',
        isim_english_name: '',
        isim_lading_name: '',
        isim_tag: '',
        isim_tag_uy: '',
        isim_beihu: '',
        isim_beihu_uy: ''
      },
      isim_cate_list: [],
      is_human: !false,
      addFormRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        name_uy: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      },
      isim_tag: [],
      isim_tag_uy: [],
      inputVisible: false,
      inputVisible_uy: false,
      inputValue: '',
      inputValue_uy: ''
    }
  },
  created() {
    this.getIsimCateList()
  },
  methods: {
    savaAddForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关数据')
        }
        const { data: res } = await this.$http.post('/isim', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.$router.push('/isim')
      })
    },
    async getIsimCateList() {
      const { data: res } = await this.$http.get('category/isim_all')
      if (res.code !== 200) {
        return this.$message.success('获取分类失败')
      }
      this.isim_cate_list = res.data
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.isim_tag.push(inputValue)
        this.addForm.isim_tag = this.isim_tag.join(',')
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleClose(tag) {
      this.isim_tag.splice(this.isim_tag.indexOf(tag), 1)
    },
    showInput_uy() {
      this.inputVisible_uy = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm_uy() {
      const inputValueUy = this.inputValue_uy
      if (inputValueUy) {
        this.isim_tag_uy.push(inputValueUy)
        this.addForm.isim_tag_uy = this.isim_tag_uy.join(',')
      }
      this.inputVisible_uy = false
      this.inputValue_uy = ''
    },
    handleClose_uy(tag) {
      this.isim_tag_uy.splice(this.isim_tag_uy.indexOf(tag), 1)
    },
    lb_change(res) {
      this.is_human = res === 1
    }
  }
}
</script>

<style scoped>
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
