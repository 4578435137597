<template>
<div>
  <BreadCrumb msg1="名称管理" msg2="名称列表" />
  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getIsimList">
          <el-button slot="append" icon="el-icon-search" @click="getIsimList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="goAddIsim">添加商标</el-button>
      </el-col>
    </el-row>
    <el-table :data="isim_list" v-loading="loading" border="">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="名称">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.isim_uy}}</div>
          <div>{{scope.row.isim_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="分类">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.isim_cate_name_uy}}</div>
          <div>{{scope.row.isim_cate_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="上传时间" prop="isim_create_time"></el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="goEditPage(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteIsim(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
import BreadCrumb from '../../components/Breadcrumb'
export default {
  name: 'Isim',
  components: {
    BreadCrumb
  },
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10
      },
      total: 0,
      isim_list: [],
      loading: true
    }
  },
  created() {
    this.getIsimList()
  },
  methods: {
    async getIsimList() {
      const { data: res } = await this.$http.get('/isim', { params: this.queryInfo })
      if (res.code !== 200) {
        this.$message.error('获取失败')
      }
      this.isim_list = res.data.data
      this.total = res.data.total
      this.loading = false
      this.$message.success('获取成功')
    },
    goAddIsim() {
      this.$router.push('isim/add')
    },
    goEditPage(id) {
      this.$router.push({ path: '/isim/edit', query: { id: id } })
    },
    async deleteIsim(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }
      const { data: res } = await this.$http.delete('isim/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getIsimList()
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getIsimList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getIsimList()
    }
  }
}
</script>

<style scoped>

</style>
