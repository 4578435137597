<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>{{msg1}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{msg2}}</el-breadcrumb-item>
  </el-breadcrumb>
</div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    msg1: String,
    msg2: String
  }
}
</script>
