<template>
<div>
  <BreadCrumb msg1="商标管理" msg2="商标列表" />
  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getShangBiaoList">
          <el-button slot="append" icon="el-icon-search" @click="getShangBiaoList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="goAddShangBiao()">添加商标</el-button>
      </el-col>
    </el-row>
    <el-table :data="shangBiaoList" v-loading="loading">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="封面" width="100">
        <template slot-scope="scope">
          <el-image style="width: 64px; height: 64px;border-radius:6px;" :src="base_image_url + '/'+scope.row.sb_image"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="商标名称">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.sb_name_uy}}</div>
          <div>{{scope.row.sb_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="商标名称">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.sb_cate_name_uy}}</div>
          <div>{{scope.row.sb_cate_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <div>注册日：{{scope.row.sb_expire_time_start}}</div>
          <div>到期日：{{scope.row.sb_expire_time_end}} </div>
        </template>
      </el-table-column>
      <el-table-column label="价格(元)" prop="sb_price"></el-table-column>
      <el-table-column label="上传时间" prop="sb_create_time"></el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="goEditPage(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteShangBiao(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
import BreadCrumb from '../../components/Breadcrumb'
export default {
  name: 'AddShangbiao',
  components: {
    BreadCrumb
  },
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      loading: true,
      total: 0,
      base_image_url: window.base_image_url,
      shangBiaoList: []
    }
  },
  created() {
    this.getShangBiaoList()
  },
  methods: {
    async getShangBiaoList() {
      const { data: res } = await this.$http.get('shangbiao', { params: this.queryInfo })
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.$message.success(res.msg)
      this.total = res.data.total
      this.shangBiaoList = res.data.data
      this.loading = false
    },
    goAddShangBiao() {
      this.$router.push('sb/add')
    },
    goEditPage(id) {
      this.$router.push({ path: '/sb/edit', query: { id: id } })
    },
    async deleteShangBiao(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }
      const { data: res } = await this.$http.delete('shangbiao/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getShangBiaoList()
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getShangBiaoList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getShangBiaoList()
    }
  }
}
</script>

<style scoped>

</style>
