<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    <el-breadcrumb-item>文章分类</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getCategoryArticleList">
          <el-button slot="append" icon="el-icon-search" @click="getCategoryArticleList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="showAddDialog()">添加分类</el-button>
      </el-col>
    </el-row>

    <el-table :data="categoryList" border v-loading="loading">
      <!-- <el-table-column label="#" type="index"></el-table-column> -->
      <el-table-column label="ID" prop="id" width="80"></el-table-column>
      <el-table-column label="分类名称" prop="article_category_name"></el-table-column>
      <el-table-column label="维文名称" prop="article_category_name_uy">
        <template slot-scope="scope">
          <span class="uy-text"> {{scope.row.article_category_name_uy}} </span>
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="article_category_order_num"></el-table-column>
      <el-table-column label="创建时间" prop="article_category_create_time"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCate(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>

  <!-- 添加文章分类对话框 -->
  <el-dialog title="添加文章分类" :visible.sync="addCateDialogVisible" width="30%">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="分类名称" prop="article_category_name">
        <el-input v-model="addForm.article_category_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="article_category_name_uy" class="uy-text">
        <el-input v-model="addForm.article_category_name_uy"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="排序" prop="article_category_order_num">
            <el-input v-model="addForm.article_category_order_num" type="number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="分类描述">
        <el-input type="textarea" placeholder="分类描述" v-model="addForm.article_category_desc"></el-input>
      </el-form-item>
      <el-form-item label="维文描述">
        <el-input type="textarea" placeholder="قوشۇمچە..." v-model="addForm.article_category_desc_uy" class="uy-text"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addCateDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveAddForm">确 定</el-button>
    </span>
  </el-dialog>

  <!-- 添加文章分类对话框 -->
  <el-dialog title="编辑文章分类" :visible.sync="editCateDialogVisible" width="30%">
    <el-form :model="editForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="分类名称" prop="article_category_name">
        <el-input v-model="editForm.article_category_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="article_category_name_uy">
        <el-input v-model="editForm.article_category_name_uy" class="uy-text"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="16">
          <el-form-item label="排序" prop="article_category_order_num">
            <el-input v-model="editForm.article_category_order_num" type="number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="分类描述">
        <el-input type="textarea" placeholder="分类描述" v-model="editForm.article_category_desc"></el-input>
      </el-form-item>
      <el-form-item label="维文描述">
        <el-input type="textarea" placeholder="维文描述" class="uy-text" v-model="editForm.article_category_desc_uy"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editCateDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveEditForm">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      loading: true,
      categoryList: [],
      addForm: {
        article_category_name: '',
        article_category_name_uy: '',
        article_category_order_num: 0,
        article_category_desc: '',
        article_category_desc_uy: ''
      },
      editForm: {},
      addFormRules: {
        article_category_name: [
          { required: 'true', message: '请填写分类名称', trigger: 'blur' }
        ],
        article_category_name_uy: [
          { required: 'true', message: '请填写维文名称', trigger: 'blur' }
        ],
        article_category_order_num: [
          { required: 'true', message: '请填排序号、默认：0', trigger: 'blur' }
        ]
      },
      addCateDialogVisible: false,
      editCateDialogVisible: false
    }
  },
  created() {
    this.getCategoryArticleList()
  },
  methods: {
    async getCategoryArticleList() {
      const { data: res } = await this.$http.get('category/article', { params: this.queryInfo })
      if (res.code !== 200) {
        this.$message.error('获取数据失败')
      }
      this.total = res.data.total
      this.categoryList = res.data.data
      this.loading = false
    },

    showAddDialog() {
      this.addCateDialogVisible = true
    },

    async showEditDialog(id) {
      const { data: res } = await this.$http.get('category/article/' + id)
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.editForm = res.data
      this.$message.success('数据获取成功')
      this.editCateDialogVisible = true
    },
    async deleteCate(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }
      const { data: res } = await this.$http.delete('category/article/' + id)
      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getCategoryArticleList()
    },
    saveAddForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const { data: res } = await this.$http.post('category/article', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.addForm = {
          article_category_name: '',
          article_category_name_uy: '',
          article_category_order_num: 0,
          article_category_desc: '',
          article_category_desc_uy: ''
        }
        this.getCategoryArticleList()
        this.addCateDialogVisible = false
      })
    },
    saveEditForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        const { data: res } = await this.$http.put('category/article', this.editForm)
        if (res.code !== 200) {
          return this.$message.error('修改失败')
        }
        this.$message.success('修改成功')
        this.$refs.addFormRef.resetFields()
        this.editForm = {
          article_category_name: '',
          article_category_name_uy: '',
          article_category_order_num: 0,
          article_category_desc: '',
          article_category_desc_uy: ''
        }
        this.getCategoryArticleList()
        this.editCateDialogVisible = false
      })
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getCategoryArticleList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getCategoryArticleList()
    }
  }
}
</script>

<style scoped>

</style>
