<template>
<div>
  <el-card>
    <div slot="header" class="clearfix">
      <span>概览</span>
    </div>

    <el-row :gutter="50">
      <el-col :span="8">
        <div class="grid-content bg-danger">
          <div class="count">{{this.report_info.shangbiao_count}}</div>
          <div class="title">商标</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-warning">
          <div class="count">{{this.report_info.isim_count}}</div>
          <div class="title">名称</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <div class="count">{{this.report_info.article_count}}</div>
          <div class="title">文章</div>
        </div>
      </el-col>
    </el-row>
  </el-card>

  <el-card style="margin-top:15px;">
    <div slot="header" class="clearfix">
      <span>数据统计</span>
    </div>
  </el-card>
</div>
</template>

<script>
export default {
  data() {
    return {
      report_info: []
    }
  },
  created() {
    this.getReportInfo()
  },
  methods: {
    async getReportInfo() {
      const {
        data: res
      } = await this.$http.get('home')
      this.report_info = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
  color: #eee;
}

.bg-success {
  background: #67C23A;
}

.bg-warning {
  background: #E6A23C;
}

.bg-danger {
  background: #F56C6C;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #409EFF;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-content .title {
  font-size: 18px;
}

.grid-content .count {
  font-size: 44px;
}

.row-bg {
  padding: 10px 0;
  // background-color: #f9fafc;
}
</style>
