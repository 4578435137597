<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    <el-breadcrumb-item>商标分类</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getSbCateList">
          <el-button slot="append" icon="el-icon-search" @click="getSbCateList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="addCate()">添加分类</el-button>
      </el-col>
    </el-row>
    <el-table :data="sb_cate_list" border style="width: 100%" v-loading="loading">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.children" :key="index" class="children">
            <div>
              <div class="uy-text">{{item.sb_cate_name_uy}}</div>
              <div>{{item.sb_cate_name}}</div>
            </div>
            <div>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(item.id)">编辑</el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCate(item.id)">删除</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="分类名称" prop="sb_cate_name">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.sb_cate_name_uy}}</div>
          <div>{{scope.row.sb_cate_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="sb_cate_create_time"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCate(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>

  <!-- 添加商标分类 -->
  <el-dialog title="添加分类" :visible.sync="addDialogVisible" width="40%">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="分类名称" prop="sb_cate_name">
        <el-input v-model="addForm.sb_cate_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="sb_cate_name_uy">
        <el-input v-model="addForm.sb_cate_name_uy" class="uy-text"></el-input>
      </el-form-item>
      <el-form-item label="父级分类" prop="name">
        <el-select v-model="addForm.sb_cate_pid" clearable placeholder="请选择" @change="selectChange">
          <el-option v-for="item in cateList" :key="item.id" :label="item.sb_cate_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类详情" prop="name">
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="addForm.sb_cate_content" :disabled="sb_content_visible"></el-input>
      </el-form-item>
      <el-form-item label="维文详情" prop="name">
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="addForm.sb_cate_content_uy" :disabled="sb_content_visible"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveAddForm">确 定</el-button>
    </span>
  </el-dialog>

  <!-- 编辑商标分类 -->
  <el-dialog title="编辑分类" :visible.sync="editDialogVisible" width="40%">
    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
      <el-form-item label="分类名称" prop="sb_cate_name">
        <el-input v-model="editForm.sb_cate_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="sb_cate_name_uy">
        <el-input v-model="editForm.sb_cate_name_uy" class="uy-text"></el-input>
      </el-form-item>
      <el-form-item label="父级分类" prop="name">
        <el-select v-model="editForm.sb_cate_pid" clearable placeholder="请选择" @change="selectChange">
          <el-option v-for="item in cateList" :key="item.id" :label="item.sb_cate_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类详情" prop="name">
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="editForm.sb_cate_content" :disabled="sb_content_visible"></el-input>
      </el-form-item>
      <el-form-item label="维文详情" prop="name">
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="editForm.sb_cate_content_uy" :disabled="sb_content_visible"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveEditForm">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      loading: true,
      sb_cate_list: [],
      addForm: {
        sb_cate_name: '',
        sb_cate_name_uy: '',
        sb_cate_pid: '',
        sb_cate_content: '',
        sb_cate_content_uy: ''
      },
      editForm: {},
      cateList: [],
      // 级联选择器配置对象
      cateProps: {
        expandTrigger: 'hover',
        checkStrictly: true,
        value: 'id',
        label: 'sb_cate_name',
        children: 'children'
      },
      addFormRules: {
        sb_cate_name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        sb_cate_name_uy: [
          { required: true, message: '请输入维文名称', trigger: 'blur' }
        ]
      },
      editFormRules: {
        sb_cate_name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ],
        sb_cate_name_uy: [
          { required: true, message: '请输入维文名称', trigger: 'blur' }
        ]
      },
      addDialogVisible: false,
      editDialogVisible: false,
      sb_content_visible: true
    }
  },
  created() {
    // this.getCateList()
    this.getSbCateList()
  },
  methods: {
    addCate() {
      this.getCateList()
      this.addDialogVisible = true
    },
    saveAddForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写分类名称')
        }
        if (this.addForm.sb_cate_pid !== '') {
          if (this.addForm.sb_cate_content === '' || this.addForm.sb_cate_content_uy === '') {
            return this.$message.warning('请填写商标详情')
          }
        }
        const { data: res } = await this.$http.post('category/shangbiao', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.addDialogVisible = false
        this.addForm = {
          sb_cate_name: '',
          sb_cate_name_uy: '',
          sb_cate_pid: '',
          sb_cate_content: '',
          sb_cate_content_uy: ''
        }
        this.getSbCateList()
        this.sb_content_visible = true
      })
    },
    async getSbCateList() {
      const { data: res } = await this.$http.get('category/shangbiao', { params: this.queryInfo })
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.sb_cate_list = res.data.data
      this.total = res.data.total
      this.$message.success('获取成功')
      this.loading = false
    },
    async getCateList() {
      const { data: res } = await this.$http.get('category/shangbiaoAll')
      if (res.code !== 200) {
        this.$message.error('获取分类数据失败')
      }
      this.cateList = res.data
    },
    selectChange(res) {
      if (res === '') {
        this.addForm.sb_cate_pid = ''
        this.sb_content_visible = true
      } else {
        this.sb_content_visible = false
      }
      this.addForm.sb_cate_pid = res
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getSbCateList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getSbCateList()
    },
    async deleteCate(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该分类, 是否继续?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmRes !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      const { data: res } = await this.$http.delete('category/shangbiao/' + id)
      if (res.code !== 201) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getSbCateList()
    },
    async showEditDialog(id) {
      this.sb_content_visible = true
      this.getCateList()
      const { data: res } = await this.$http.get('category/shangbiao/' + id)
      if (res.code !== 200) {
        return this.$message.error('获取数据失败')
      }
      this.editForm = res.data
      if (res.data.sb_cate_content !== '' || res.data.sb_cate_content_uy !== '') {
        this.sb_content_visible = false
      }
      if (res.data.sb_cate_pid === 0) {
        this.editForm.sb_cate_pid = ''
      }
      this.editDialogVisible = true
    },
    saveEditForm() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写分类名称')
        }
        if (this.editForm.sb_cate_pid !== '') {
          if (this.editForm.sb_cate_content === '' || this.editForm.sb_cate_content_uy === '') {
            return this.$message.warning('请填写商标详情')
          }
        }
        const { data: res } = await this.$http.put('category/shangbiao', this.editForm)
        if (res.code !== 201) {
          return this.$message.error('修改失败')
        }
        this.$message.success('修改成功')
        this.editDialogVisible = false
        this.getSbCateList()
        this.sb_content_visible = true
      })
    }
  }
}
</script>

<style scoped>
.children {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
</style>
