<template>
<div>
  <BreadCrumb msg1="系统设置" msg2="系统信息" />
  <el-card>
    <div slot="header" class="clearfix">
      <span>服务器信息</span>
    </div>
    <el-form>
      <el-row>
        <el-col :span="12">
          <el-form-item label="操作系统:" label-width="150">
            <span class="erver_info_item">{{this.server_info['php_os']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运行环境:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_software']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="运行环境:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_name']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="WEB服务端口:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_port']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="网站文档目录:" label-width="150">
            <span class="erver_info_item">{{this.server_info['document_root']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="浏览器信息:" label-width="150">
            <span class="erver_info_item">{{this.server_info['http_user_agent']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="通信协议:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_protocol']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="请求方法:" label-width="150">
            <span class="erver_info_item">{{this.server_info['request_method']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上传附件限制:" label-width="150">
            <span class="erver_info_item">{{this.server_info['upload_max_file_size']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="执行时间限制:" label-width="150">
            <span class="erver_info_item">{{this.server_info['max_execution_time']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="服务器时间:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_time']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="北京时间:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_bj_time']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="服务器域名/IP:" label-width="150">
            <span class="erver_info_item">{{this.server_info['server_domain']}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户的IP地址:" label-width="150">
            <span class="erver_info_item">{{this.server_info['remote_ip_addr']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="剩余空间" label-width="150">
            <span class="erver_info_item">{{this.server_info['disk_free_space']}}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</div>
</template>

<script>
import BreadCrumb from '../../components/Breadcrumb'
export default {
  name: 'Isim',
  components: {
    BreadCrumb
  },
  data() {
    return {
      server_info: []
    }
  },
  created() {
    this.getServerInfo()
  },
  methods: {
    async getServerInfo() {
      const { data: res } = await this.$http.get('server')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.server_info = res.data
      this.$message.success('获取成功')
    }
  }
}
</script>

<style scoped>

</style>
