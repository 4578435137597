import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

import VueQuillEditor from 'vue-quill-editor'
import * as Quill from 'quill'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import 'element-ui/lib/theme-chalk/index.css'
import './assets/fonts/iconfont.css'
import '../src/assets/css/global.css'

import TreeTable from 'vue-table-with-tree-grid'

import axios from 'axios'

const fonts = [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'sans-serif']
const Font = Quill.import('formats/font')
Font.whitelist = fonts
Quill.register(Font, true)

Vue.use(VueQuillEditor)
axios.defaults.baseURL = 'https://arzu.mldqanal.cn/admin/'

axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

axios.interceptors.response.use(config => {
  if (config.data.status === 1002 || config.data.status === 1003 || config.data.status === 1004) {
    window.sessionStorage.clear()
    router.replace({
      path: '/login'
    })
  }
  return config
})
Vue.prototype.$http = axios
Vue.component('tree-table', TreeTable)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
