<template>
<el-container class="home-container">
  <!-- 头部区域 -->
  <el-header>
    <div>
      <img src="../../assets/logo.png" alt="">
      <span>Arzu·内容管理系统</span>
    </div>
    <el-button type="info" @click="logout">退出</el-button>
  </el-header>
  <!-- 页面主题区域 -->
  <el-container>
    <!-- 侧边栏 -->
    <el-aside :width="toogleColapse ? '64px':'200px'">
      <!-- <div class="toggle" @click="toggleCollapse">
        <i class="iconfont icon-collapseleft"></i>
      </div> -->
      <el-menu background-color="#303133" text-color="#fff" active-text-color="#ffd04b" :unique-opened="true" :collapse="toogleColapse" :collapse-transition="false" router :default-active="activePath">
        <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
          <template slot="title">
            <i :class="iconsObj[item.id]"></i>
            <span>{{item.authName}}</span>
          </template>
          <el-menu-item :index="'/'+subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState('/'+subItem.path)">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>{{subItem.authName}}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <!-- 侧边内容主题 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</el-container>
</template>

<script>
export default {
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  data() {
    return {
      // 左侧菜单数据
      menuList: [{
          id: 101,
          authName: '内容管理',
          path: null,
          children: [{
            id: 101,
            authName: '添加文章',
            path: 'article/add',
            children: []
          }, {
            id: 102,
            authName: '文章列表',
            path: 'article',
            children: []
          }]
        },
        {
          id: 201,
          authName: '名称管理',
          path: null,
          children: [{
              id: 202,
              authName: '添加名称',
              path: 'isim/add',
              children: []
            },
            {
              id: 203,
              authName: '名称列表',
              path: 'isim',
              children: []
            }
          ]
        },
        {
          id: 301,
          authName: '商标管理',
          path: null,
          children: [{
            id: 302,
            authName: '添加商标',
            path: 'sb/add',
            children: []
          }, {
            id: 303,
            authName: '商标列表',
            path: 'sb',
            children: []
          }]
        },
        {
          id: 401,
          authName: '分类管理',
          path: null,
          children: [{
            id: 402,
            authName: '文章分类',
            path: 'category/article',
            children: []
          }, {
            id: 403,
            authName: '商标分类',
            path: 'category/sb',
            children: []
          }, {
            id: 404,
            authName: '名称分类',
            path: 'category/isim',
            children: []
          }]
        }, {
          id: 501,
          authName: '小程序管理',
          path: null,
          children: [{
            id: 502,
            authName: '轮播图',
            path: 'banner',
            children: []
          }, {
            id: 503,
            authName: '菜单管理',
            path: 'wechat/menus',
            children: []
          }]
        },
        {
          id: 601,
          authName: '系统设置',
          path: null,
          children: [{
              id: 602,
              authName: '系统信息',
              path: 'sys/info',
              children: []
            }
            //  {
            //   id: 603,
            //   authName: '密码安全',
            //   path: 'password',
            //   children: []
            // }
          ]
        }
      ],
      iconsObj: {
        101: 'iconfont icon-article',
        201: 'iconfont icon-name',
        301: 'iconfont icon-shangbiao',
        401: 'iconfont icon-category',
        501: 'iconfont icon-mini-program-fill',
        601: 'iconfont icon-Settingscontroloptions'
      },
      toogleColapse: false,
      // 被激活的侧边栏连接
      activePath: ''
    }
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },

    toggleCollapse() {
      this.toogleColapse = !this.toogleColapse
    },
    // 保存连接的激活状况
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  color: #fff;

  div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }

    img {
      height: 44px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 15px;
    }
  }
}

.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #303133;

  .el-menu {
    border-right: none;
    color: #eee;
  }
}

.el-main {
  background-color: #eaedf1;
  width: 100%;
}

.iconfont {
  margin-right: 10px;
}

.toggle {
  background-color: #333;
  color: #909399;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
